import React, { Component } from 'react'
import Item from './Item'

export class Items extends Component {
  render() {
    return (
      <main>
         {this.props.items.map(el => (
            <Item orders={this.props.orders} onShowItem={this.props.onShowItem} onShowBooking={this.props.onShowBooking} key={el.id} item={el} onAdd={this.props.onAdd} /> /*1) подключаем сюда многократно по циклу map компонент Item,*/
                                          /*при этом при каждой итерации передаем очередную-i строку базы данных (el из цикла map(el)), */
                                          /*а также что бы не вылезала ошибка, ключ строки БД т.е. id: key={el.id}*/
                                          /*2) пробрасываем дальше из App в Item переменную onAdd="наша функция (addToOrder(item)) из App по добавлению товаров в корзину"*/
                                          /*3) пробрасываем дальше из App в Item функцию выведения полной информации о товаре: onShowItem()*/
         ))}
      </main>
    )
  }
}

export default Items
