import React, { Component } from 'react'
import { GrSend } from "react-icons/gr"
import { IoClose } from "react-icons/io5"
//регулярное выражение для проверки инпута
let loginError = 1
let passError = 1
let userAuthorization = new Object()//создаем объект userAuthorization
userAuthorization.name = ''//свойство обекта userAuthorization
userAuthorization.login = ''//свойство обекта userAuthorization

export class ShowBooking extends Component {
   /*КОНСТРУКТОР для работы с состояниями. Такой себе хаб*/                              
   constructor (props){/*здесь мы принимаем состояния (пробсы) от родительского класса*/
   super(props)/*а здесь мы отправляем состояния (пробсы) в родительский класс*/
   /*this.state - это обект содержащий в себе различные состояния*/
   this.state = {
      loginDirty: false,
      loginError: 'Error',

      passDirty: false,
      passError: 'Error',

      formValid: true
   }
   /*Регистрируем наши функции:*/
   this.blurHandler = this.blurHandler.bind(this)
   this.onChangeHandler = this.onChangeHandler.bind(this)
   this.loginHandler = this.loginHandler.bind(this)
   this.passHandler = this.passHandler.bind(this)
   this.formValidHandler = this.formValidHandler.bind(this)
   
   }/*закрываем объект конструктор*/
  
   render() {
      return (
      <div className='full-item' id='booking'>
         <div>{/*фон}*/}
            <IoClose className='exit' onClick={() => this.props.onShowLogin()}/>
            <h3> Login</h3>
            <br/>
            <div className='inputDiv'>
               <div className='divInputLogin'>
                  <div className='divInfo' >{this.state.loginDirty == true && this.state.loginError}{this.props.loginBusy}</div>
                  <input className='inputLogin' type="text"  placeholder='Login'  maxLength = 'true' onBlur={event => this.blurHandler('login', event.target.value)} onChange = {event => this.onChangeHandler('login', event.target.value)} ></input>
               </div>
               <div className='divInputPass'>
                  <div className='divInfo' >{this.state.passDirty == true && this.state.passError}</div>
                  <input className='inputPass' type="password"  placeholder='Password'  maxLength = 'true' onBlur={event => this.blurHandler('pass', event.target.value)} onChange = {event => this.onChangeHandler('pass', event.target.value)} ></input>
               </div>
               {/*<input className='text' type="text"  placeholder='комментарии' maxLength = 'true' onChange= {event => this.props.userData.comment = event.target.value}></input>*/}
            </div>
            <button className='ico' id = {this.state.formValid == false ? 'icoActive': 'icoNoActive'} disabled={this.state.formValid} onClick={() => this.props.sendLogin(userAuthorization)} ><GrSend /></button>
            <br/>
            <br/>
            <h4 className='RegistrationSend' onClick={() => this.Registration()}> Register </h4>
            
            <h3>{this.props.info}</h3>
         </div>
      </div>
    )
  }

  

   //Функция отслеживает любые изменения во всех инпутах
  onChangeHandler(input, value){//input - переданное в эту функцию название инпута. value - получаем содержание из инпута
   if (input == 'login'){//если функция вызвана инпутом email
      userAuthorization.login = value
      this.loginHandler()
   }

   if (input == 'pass'){//если функция вызвана инпутом email
      userAuthorization.pass = value
      this.passHandler()
   }      
 }
  
  //Функция отслеживает выход из всех инпутов
  blurHandler(input, value){
   if (input == 'login'){//если функция вызвана инпутом email
      userAuthorization.login = value
      this.setState({loginDirty: true})
      this.loginHandler()
   }
   if (input == 'pass'){//если функция вызвана инпутом email
      userAuthorization.pass = value
      this.setState({passDirty: true})
      this.passHandler()
   }
  }

     //Функция валидации инпута Login
   loginHandler(){
      if (!userAuthorization.login) {//если инпут пустой
         loginError = 1
         this.setState({loginError: 'Login cannot be empty'})
         return
      }
      if (userAuthorization.login.length > 4){
         this.setState({loginError: ''})
         loginError = 0//для функции formValidHandler()
      }else{
         loginError = 1
         this.setState({loginError: 'Login at least 5 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте
   }

     //Функция валидации инпута Pass
   passHandler(){
      if (!userAuthorization.pass) {//если инпут пустой
         passError = 1
         this.setState({passError: 'Password cannot be empty'})
         return
      }
      if (userAuthorization.pass.length > 7){
         this.setState({passError: ''})
         passError = 0//для функции formValidHandler()
      }else{
         passError = 1
         this.setState({passError: 'Password at least 8 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте
   }

   //Валидация всей формы, акивирует кнопку отправить
   formValidHandler(){
      if (loginError + passError == 0) {
         this.setState({formValid: false})
      }else{
         this.setState({formValid: true})
      }

   }
   //При нажатии на кнопку регистрация скрываем окно Вход и показываем окно Регистрация
   Registration(){
      this.props.onShowLogin() 
      this.props.onShowRegistration() 
   }
   ////Автозапуск функция, проверяет автозаполнение всех полей из токена
   //getGoods() {//getGoods штатная функция для изменения состояний в копонентах-классах аналог useEffect в комонентах функциях. Не требуется регистрация в Конструкторе//
   //   userAuthorization.name = String(this.props.userData.name)
   //   userAuthorization.email = String(this.props.userData.email)
   //   this.nameHandler()
   //   this.emailHandler() 
   //   if (this.state.nameDirty == false && this.state.emailDirty == false && this.props.userData.name.length > 0 && this.props.userData.email.length > 0) {
   //      this.setState({formValid: false})
   //   }
   //}




}

export default ShowBooking