/*ФИЛЬТРЫ товаров*/

import React, { Component } from 'react'
export class Categories extends Component {

  render() {
    return (
      //Категории выводим на экран циклом map://
      <div className='categories'>
         {/*{this.state.categories.map(el => (*/}
         {this.props.categories.map(el => (
            //onClick={() => this.props.chooseCategory(el.key)} - это обработчик событий: при нажатии на кнопку вызывает пепеданную сюда функцию chooseCategory //
            //и передает в нее key (id) категории (фильтра) соответствующего нажатой кнопке//
            <div key={el.category_id } onClick={() => this.props.chooseCategory(el.category_id )}>{el.category_name}</div>
         ))}
      </div>
    )
  }
  
}

export default Categories