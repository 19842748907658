import React, { Component } from 'react'
import { GrSend } from "react-icons/gr"
import { IoClose } from "react-icons/io5"
//регулярное выражение для проверки инпута
const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
//let email = ''
let nameError  = 1
let emailError = 1
let loginError = 1
let passError = 1
let phoneError = 1
let addressError = 1
let userReg = new Object()//создаем объект userReg
userReg.name = ''//свойство обекта userReg
userReg.email = ''//свойство обекта userReg

export class ShowBooking extends Component {
   /*КОНСТРУКТОР для работы с состояниями. Такой себе хаб*/                              
   constructor (props){/*здесь мы принимаем состояния (пробсы) от родительского класса*/
   super(props)/*а здесь мы отправляем состояния (пробсы) в родительский класс*/
   /*this.state - это обект содержащий в себе различные состояния*/
   this.state = {
      nameDirty: false,
      nameError: 'Error',

      emailDirty: false,
      emailError: 'Error',

      loginDirty: false,
      loginError: 'Error',

      passDirty: false,
      passError: 'Error',

      phoneDirty: false,
      phoneError: 'Error',

      addressDirty: false,
      addressError: 'Error',

      formValid: true
   }
   /*Регистрируем наши функции:*/
   this.blurHandler = this.blurHandler.bind(this)
   this.onChangeHandler = this.onChangeHandler.bind(this)
   this.emailHandler = this.emailHandler.bind(this)
   this.nameHandler = this.nameHandler.bind(this)
   this.loginHandler = this.loginHandler.bind(this)
   this.passHandler = this.passHandler.bind(this)
   this.phoneHandler = this.phoneHandler.bind(this)
   this.addressHandler = this.addressHandler.bind(this)
   this.formValidHandler = this.formValidHandler.bind(this)

   
   }/*закрываем объект конструктор*/
  
   render() {
      return (
      <div className='full-item' id='booking'>
         <div>{/*фон}*/}
            <IoClose className='exit' onClick={() => this.props.onShowRegistration()}/>
            <h3> Registration</h3>
            <br/>
            <div className='inputDiv'>
               <div className='divInputName'>
                  <div className='divInfo' >{this.state.nameDirty == true && this.state.nameError}</div>
                  <input className='inputName' type="text"  placeholder='Your name'  maxLength = 'true' onBlur={event => this.blurHandler('name', event.target.value)} onChange = {event => this.onChangeHandler('name', event.target.value)} ></input>
               </div>
               <div className='divInputEmail'>
                  <div className='divInfo' >{this.state.emailDirty == true && this.state.emailError}</div>
                  <input className='inputEmail' type="text"  placeholder='E-mail'  maxLength = 'true' onBlur={event => this.blurHandler('email', event.target.value)} onChange = {event => this.onChangeHandler('email', event.target.value)} ></input>
               </div>
               <div className='divInputPhone'>
                  <div className='divInfo' >{this.state.phoneDirty == true && this.state.phoneError}</div>
                  <input className='inputPhone' type="text"  placeholder='Phone'  maxLength = 'true' onBlur={event => this.blurHandler('phone', event.target.value)} onChange = {event => this.onChangeHandler('phone', event.target.value)} ></input>
               </div>
               <div className='divInputAddress'>
                  <div className='divInfo' >{this.state.addressDirty == true && this.state.addressError}</div>
                  <input className='inputAddress' type="text"  placeholder='Address'  maxLength = 'true' onBlur={event => this.blurHandler('address', event.target.value)} onChange = {event => this.onChangeHandler('address', event.target.value)} ></input>
               </div>
               <div className='divInputLogin'>
                  <div className='divInfo' >{this.state.loginDirty == true && this.state.loginError}{this.props.loginBusy}</div>
                  <input className='inputLogin' type="text"  placeholder='Login'  maxLength = 'true' onBlur={event => this.blurHandler('login', event.target.value)} onChange = {event => this.onChangeHandler('login', event.target.value)} ></input>
               </div>
               <div className='divInputPass'>
                  <div className='divInfo' >{this.state.passDirty == true && this.state.passError}</div>
                  <input className='inputPass' type="password"  placeholder='Password'  maxLength = 'true' onBlur={event => this.blurHandler('pass', event.target.value)} onChange = {event => this.onChangeHandler('pass', event.target.value)} ></input>
               </div>
               {/*<input className='text' type="text"  placeholder='комментарии' maxLength = 'true' onChange= {event => this.props.userData.comment = event.target.value}></input>*/}
            </div>
            <button className='ico' id = {this.state.formValid == false ? 'icoActive': 'icoNoActive'} disabled={this.state.formValid} onClick={() => this.props.sendRegistration(userReg)} ><GrSend /></button>
            <br/>
            
            <h3>{this.props.info}</h3>
         </div>
      </div>
    )
  }

  

   //Функция отслеживает любые изменения во всех инпутах
  onChangeHandler(input, value){//input - переданное в эту функцию название инпута. value - получаем содержание из инпута
   this.props.loginBusyFunc()//функция в APP для обнуления ошибки, что логин уже занят

   if (input == 'name'){//если функция вызвана инпутом name
      userReg.name = value
      this.nameHandler()
   }
   if (input == 'email'){//если функция вызвана инпутом email
      userReg.email = value
      this.emailHandler()
   }

   if (input == 'login'){//если функция вызвана инпутом email
      userReg.login = value
      this.loginHandler()
   }

   if (input == 'pass'){//если функция вызвана инпутом email
      userReg.pass = value
      this.passHandler()
   }

   if (input == 'phone'){//если функция вызвана инпутом email
      userReg.phone = value
      this.phoneHandler()
   }

   if (input == 'address'){//если функция вызвана инпутом email
      userReg.address = value
      this.addressHandler()
   }      
 }
  
  //Функция отслеживает выход из всех инпутов
  blurHandler(input, value){
   if (input == 'name'){//если функция вызвана инпутом email
      userReg.name = value
      this.setState({nameDirty: true})
      this.nameHandler()
   }
   if (input == 'email'){//если функция вызвана инпутом email
      userReg.email = value
      this.setState({emailDirty: true})
      this.emailHandler()
   }
   if (input == 'login'){//если функция вызвана инпутом email
      userReg.login = value
      this.setState({loginDirty: true})
      this.loginHandler()
   }
   if (input == 'pass'){//если функция вызвана инпутом email
      userReg.pass = value
      this.setState({passDirty: true})
      this.passHandler()
   }
   if (input == 'phone'){//если функция вызвана инпутом email
      userReg.phone = value
      this.setState({phoneDirty: true})
      this.phoneHandler()
   }
   if (input == 'address'){//если функция вызвана инпутом email
      userReg.address = value
      this.setState({addressDirty: true})
      this.addressHandler()
   }
  }

  //Функция валидации инпута Name
   nameHandler(){
      if (!userReg.name) {//если инпут пустой
         nameError = 1
         this.setState({nameError: "Can't be empty"})
         return
      }
      if (userReg.name.length > 2){
         this.setState({nameError: ''})
         nameError = 0//для функции formValidHandler()
      }else{
         nameError = 1
         this.setState({nameError: 'At least 3 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте

   }

  //Функция валидации инпута email
   emailHandler(){
      if (!userReg.email) {//если инпут пустой
         emailError = 1
         this.setState({emailError: 'E-mail cannot be empty'})
         return
      }
      if (re.test(String (userReg.email))) {
         this.setState({emailError: ''})
         emailError = 0//для функции formValidHandler()
      }else{
         //emailError = 1
         emailError = 0
         this.setState({emailError: 'Invalid E-mail'})
      }
      //:re-это константа (находится в самом верху) содержащая регулярное выражение для проверки почты
      //test - штатная функция проверяющая регулярные выражения и данные из инпута, возвращает true или false
      //String - преобразуем содержание инпута в строку(не обязательно)
      //this.state.email - состояние зависящие от содержания инпута
      // если введенный E-mail соответствует регулярному выражению, то меняем состояние ошибки: && this.setState({emailError: 'OK'})
      this.formValidHandler()//Функция валидации всей формы, акивирует кнопку отправить
   }

     //Функция валидации инпута Login
   loginHandler(){
      if (!userReg.login) {//если инпут пустой
         loginError = 1
         this.setState({loginError: 'Login cannot be empty'})
         return
      }
      if (userReg.login.length > 4){
         this.setState({loginError: ''})
         loginError = 0//для функции formValidHandler()
      }else{
         loginError = 1
         this.setState({loginError: 'Login at least 5 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте
   }

     //Функция валидации инпута Pass
   passHandler(){
      if (!userReg.pass) {//если инпут пустой
         passError = 1
         this.setState({passError: 'Password cannot be empty'})
         return
      }
      if (userReg.pass.length > 7){
         this.setState({passError: ''})
         passError = 0//для функции formValidHandler()
      }else{
         passError = 1
         this.setState({passError: 'Password at least 8 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте
   }

   //Функция валидации инпута Phone
   phoneHandler(){
      if (!userReg.phone) {//если инпут пустой
         phoneError = 1
         this.setState({phoneError: 'Phone cannot be empty'})
         return
      }
      if (userReg.phone.length > 10){
         this.setState({phoneError: ''})
         phoneError = 0//для функции formValidHandler()
      }else{
         phoneError = 1
         this.setState({phoneError: 'Phone no less than 11 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте
   }
   //Функция валидации инпута Phone
   addressHandler(){
      if (!userReg.address) {//если инпут пустой
         addressError = 1
         this.setState({addressError: 'Address cannot be empty'})
         return
      }
      if (userReg.address.length > 19){
         this.setState({addressError: ''})
         addressError = 0//для функции formValidHandler()
      }else{
         addressError = 1
         this.setState({addressError: 'Address of at least 20 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте
   }

   //Валидация всей формы, акивирует кнопку отправить
   formValidHandler(){
      if (nameError + emailError + loginError + passError + phoneError + addressError== 0) {
         this.setState({formValid: false})
      }else{
         this.setState({formValid: true})
      }

   }
   ////Автозапуск функция, проверяет автозаполнение всех полей из токена
   //getGoods() {//getGoods штатная функция для изменения состояний в копонентах-классах аналог useEffect в комонентах функциях. Не требуется регистрация в Конструкторе//
   //   userReg.name = String(this.props.userData.name)
   //   userReg.email = String(this.props.userData.email)
   //   this.nameHandler()
   //   this.emailHandler() 
   //   if (this.state.nameDirty == false && this.state.emailDirty == false && this.props.userData.name.length > 0 && this.props.userData.email.length > 0) {
   //      this.setState({formValid: false})
   //   }
   //}




}

export default ShowBooking