import React from 'react'
let currentYear = new Date().getFullYear();


export default function Footer() {
  return (
    <footer>
      All rights reserved &copy; {currentYear}
   </footer>
  )
}
