//компонент корзна//
import React, { Component } from 'react'
import { FaTrash } from 'react-icons/fa'/*импортируем иконку удаления товара из корзины*/
import { PiMinusSquareLight } from "react-icons/pi"
import { PiPlusSquareLight } from "react-icons/pi"

export class Order extends Component {
   
   //round(int) {//функция округления любого числа//
   //   new Intl.NumberFormat().format(int)
   //}
   
   render() {
    return (
      <div className='item'>
         <img src={"./img/" + this.props.item.img} />
         <h2 className='title'>{this.props.item.title}</h2>
         <b className='price'>{this.props.item.price}$ </b>
         {/*количество каждого товара*/}
         <div className='countGoods'>
            <b className='plusMinusButon' onClick={() => this.props.countGoodsOrder(this.props.item, 1)}> + </b>
            <div className='inputDiv'>
               <div className='input2'>{this.props.item.countGoods}</div>{/*псевдо инпут подсуним под настоящий инпут с прозрачным цветом текста*/}
               <input type='number'step="1" min="1" max="27" size="4" aria-placeholder={this.props.item.countGoods} onChange= {event => this.props.countGoodsOrder(this.props.item, + event.target.value)} />{/* + event: плюсом мы переводим текст в число. количество*/}
             </div> 
            <b className='plusMinusButon' onClick={() => this.props.countGoodsOrder(this.props.item, -1)}> - </b>
         </div>
         {/*остаток товара на складе*/}
         <b className='balance'> rem {this.props.item.balance>100?'больше 100':this.props.item.balance}</b>{/*функция округления любого числа*/}
         <br/>
         <b className='itemSum'> {new Intl.NumberFormat().format((this.props.item.price) * (this.props.item.countGoods))}$</b>{/*функция округления любого числа*/}
         {/*иконка "удалить товар из корзины":*/}
         <FaTrash className='delete-icon' onClick={() => this.props.onDelete(this.props.item.id)}/>
                     {/* FaTrash: вызываем иконку из библиотеки 'react-icons/fa'*/}
                     {/* onClick={() =>}: по нажатию на эту иконку запускаем безимянную функцию, которая делает следующее:*/}
                     {/* передает в полученную из App функцию (onDelete=deleteOrder(id)), id товара соответствующего нажатой иконке*/}
         <br/>
      </div>
      )
   }


}

export default Order