import React, { Component } from 'react'
import { MdDone } from "react-icons/md"
import { IoIosArrowBack } from "react-icons/io"

//********************Youtube pleer*********************************/  
const YouTube = (src) => {
   return (
         <iframe width="100%" //height="200"
            src={src}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
         </iframe> )
}
//*******************************************************************/ 


export class Contacts extends Component {
  render() {   
    return (
      <div className='full-item' id='booking'>
         <div>{/*фон}*/}
            <div className='icoBack' onClick={() => this.props.onShowContacts()}><IoIosArrowBack /></div>
            {/*<p class="stishok"> {/*нужен чтобы сработал <br/> }*/}
            {/*Здесь все данные идут из БД*/}
            <h2>{this.props.contacts.name}</h2>
            <br/>
            <h4>ADDRESS</h4>
            <p>{this.props.contacts.address}</p>
            <br/>
            <div class="map-responsive">
               {/*Карта Googl:*/}
               <iframe src={this.props.contacts.src_geolocation} width="500" height="150" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <br/>
            <h4>PHONE</h4>
            <p>{this.props.contacts.telephone_1}</p>
            <p>{this.props.contacts.telephone_2}</p>
            <p>{this.props.contacts.telephone_3}</p>
            <br/>
            <h4>MAIL</h4>
            <p>{this.props.contacts.mail}</p>
            <br/>
            <p>{this.props.contacts.social_1}</p>
            <br/>
            <p>{this.props.contacts.social_2}</p>
            <br/>
            <p>{this.props.contacts.social_3}</p>
            <br/>
            <img className='balanceOK' src={this.props.PHPserver + "img/contacts/" + this.props.contacts.img_1} />
            <br/>
            {/*<img className='balanceOK' src={this.props.PHPserver + "img/contacts/" + this.props.contacts.img_3} />*/}
            <br/>
            {YouTube(this.props.contacts.youtube)} {/*запускаем наш плеер ютюб*/}
            <div className='icoDone' onClick={() => this.props.onShowContacts()}><MdDone /></div>
            {/*<img src={"./img/" + this.props.item.img} onClick={() => this.props.onShowInfo()} />*/}
            {/*</p>*/}
         </div>
         
      </div>
    )
  }
}

export default Contacts