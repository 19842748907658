/////////////////ПРЕДЗАКАЗ////////////////////////
import React, { Component } from 'react'
import { GrSend } from "react-icons/gr"
import { IoIosArrowBack } from "react-icons/io"
//регулярное выражение для проверки инпута
const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
let name  = ''
let email = ''
let nameError  = 1
let emailError = 1

export class ShowBooking extends Component {
   /*КОНСТРУКТОР для работы с состояниями. Такой себе хаб*/                              
   constructor (props){/*здесь мы принимаем состояния (пробсы) от родительского класса*/
   super(props)/*а здесь мы отправляем состояния (пробсы) в родительский класс*/
   /*this.state - это обект содержащий в себе различные состояния*/
   this.state = {
      nameDirty: false,
      nameError: 'Error',

      emailDirty: false,
      emailError: 'Error',

      formValid: true
   }
   /*Регистрируем наши функции:*/
   this.blurHandler = this.blurHandler.bind(this)
   this.onChangeHandler = this.onChangeHandler.bind(this)
   this.emailHandler = this.emailHandler.bind(this)
   this.nameHandler = this.nameHandler.bind(this)
   this.formValidHandler = this.formValidHandler.bind(this)

   
   }/*закрываем объект конструктор*/
  
   render() {
      return (
      <div className='full-item' id='booking'>
         <div>{/*фон}*/}
            <div className='icoBack' onClick={() => this.props.onShowBooking(this.props.item)}><IoIosArrowBack /></div>
            <img src={"./img/" + this.props.item.img} onClick={() => this.props.onShowBooking(this.props.item)} />
                                                      {/*при нажатии на изображение onClick вызовет безымянную функци ({() => ...}), которая в свою очередь...*/}
                                                      {/*вызовет функцию переданную сюда из App (onShowItem(с товаром: this.props.item), выводящую окно с полным описанием товара*/}
            
            <h3> Leave your e-mail and we will notify you as soon as {this.props.item.title} is in stock</h3>
            <br/>
            <div className='inputDiv'>
               <div className='divInputName'>
                  <div className='divInfo' >{this.state.nameDirty == true && this.state.nameError}</div>
                  <input className='inputName' type="text"  placeholder='Your name' defaultValue = {this.props.userData.name} maxLength = 'true' onBlur={event => this.blurHandler('name', event.target.value)} onChange = {event => this.onChangeHandler('name', event.target.value)} ></input>
               </div>
               <div className='divInputEmail'>
                  <div className='divInfo' >{this.state.emailDirty == true && this.state.emailError}</div>
                  <input className='inputEmail' type="text"  placeholder='E-mail' defaultValue = {this.props.userData.email} maxLength = 'true' onBlur={event => this.blurHandler('email', event.target.value)} onChange = {event => this.onChangeHandler('email', event.target.value)} ></input>
               </div>
               <div className='divInputInt'>
               <div className='divInfo'></div>
                  <input className='inputInt' type='number'step="1" min="1" max="25" size="10" placeholder='amt' onChange= {event => this.props.item.count = event.target.value}></input>{/*количество*/}
               </div>
               <input className='text' type="text"  placeholder='Comments' maxLength = 'true' onChange= {event => this.props.item.comment = event.target.value}></input>
            </div>
            <button className='ico' id = {this.state.formValid == false ? 'icoActive': 'icoNoActive'} disabled={this.state.formValid} onClick={() => this.props.sendBooking(this.props.item)} ><GrSend /></button>
            <br/>
            
            {/*кнопка добавить в корзину с условием:*/}
            {this.props.item.balance>0 && <div className='add-to-cart' onClick={() => this.props.onAdd(this.props.item)}>+</div>}
                                          {/*При нажатии этой кнопки (onClick) запускается безимянная функция ({() => })*/}
                                          {/*которая вызывает функцию добавления товаров в корзину addToOrder(item) находящуюся в App*/}
                                          {/*и переданую сюда (this.props.onAdd) в переменной onAdd */}
                                          {/*при этом передаем в эту функцию текущий товар этой  нажатой кнопки: onAdd(this.props.item)*/}
            <h3>{this.props.info}</h3>
         </div>
      </div>
    )
  }

  

   //Функция отслеживает любые изменения во всех инпутах
  onChangeHandler(input, value){//thisState - переданное в эту функцию название (текст) сосояния которое нужно поменять. value - получаем содержание из инпута
   if (input == 'name'){//если функция вызвана инпутом name
      this.setState(prevState=>({prevState, name: value}))
      //тоже самое:
      //this.setState({name: value}) //меняем состояние name
      this.props.item.inputName = value //меняем пробс name
      name = value
      this.nameHandler()
   }
   if (input == 'email'){//если функция вызвана инпутом email
      this.setState((prevState) =>({prevState, email: value}))
      this.props.item.inputEmail = value //меняем пробс email
      email = value
      this.emailHandler()                                                       //emailHandler(value): в функцию emailHandler передаем value инпута, а не используем сосояние инпута, потому что состояния не поспевают за копи-пастой в импуте при IF
   }
      
 }
  
  //Функция отслеживает выход из инпута
  blurHandler(input, value){
   if (input == 'name'){//если функция вызвана инпутом email
      name = value
      this.setState({nameDirty: true})
      this.nameHandler()
   }
   if (input == 'email'){//если функция вызвана инпутом email
      email = value
      this.setState({emailDirty: true})
      this.emailHandler()
   }
  }

  //Функция валидации инпута Name
   nameHandler(){
      if (!name) {//если инпут пустой
         nameError = 1
         this.setState({nameError: "Can't be empty"})
         return
      }
      if (name.length > 2){
         this.setState({nameError: ''})
         nameError = 0//для функции formValidHandler()
      }else{
         nameError = 1
         this.setState({nameError: 'At least 3 characters'})
      }
      this.formValidHandler()//передаем в функцию валидации всей формы:formValidHandler() название функции которая ее вызвала и наличие или отсутствие (Error = 1 или 0) ошибки в запогненном инпуте

   }

  //Функция валидации инпута email
   emailHandler(){
      if (!email) {//если инпут пустой
         emailError = 1
         this.setState({emailError: "Can't be empty"})
         return
      }
      if (re.test(String (email))) {
         this.setState({emailError: ''})
         emailError = 0//для функции formValidHandler()
      }else{
         emailError = 1
         this.setState({emailError: 'Invalid E-mail'})
      }
      //:re-это константа (находится в самом верху) содержащая регулярное выражение для проверки почты
      //test - штатная функция проверяющая регулярные выражения и данные из инпута, возвращает true или false
      //String - преобразуем содержание инпута в строку(не обязательно)
      //this.state.email - состояние зависящие от содержания инпута
      // если введенный E-mail соответствует регулярному выражению, то меняем состояние ошибки: && this.setState({emailError: 'OK'})
      this.formValidHandler()//Функция валидации всей формы, акивирует кнопку отправить
   }

   //Валидация всей формы, акивирует кнопку отправить
   formValidHandler(){
      if (this.props.userData.name!=='') {//активируем автозаполненное имя если пользователь уже залогинин
         nameError=0
      }  
      if (nameError + emailError == 0) {
         this.setState({formValid: false})
      }else{
         this.setState({formValid: true})
      }

   }
   //Автозапуск функция, проверяет автозаполнение всех полей из токена
   getGoods() {//getGoods штатная функция для изменения состояний в копонентах-классах аналог useEffect в комонентах функциях. Не требуется регистрация в Конструкторе//
      name = String(this.props.userData.name)
      email = String(this.props.userData.email)
      this.nameHandler()
      this.emailHandler() 
      if (this.state.nameDirty == false && this.state.emailDirty == false && this.props.userData.name.length > 0 && this.props.userData.email.length > 0) {
         this.setState({formValid: false})
      }
   }




}

export default ShowBooking