import React, { Component } from 'react'
import { MdDone } from "react-icons/md"
import { IoIosArrowBack } from "react-icons/io"

//********************Youtube pleer*********************************/  
const YouTube = (src) => {
   return (
         <iframe width="100%" //height="200"
            src={src}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
         </iframe> )
}
//*******************************************************************/ 


export class ShowBooking extends Component {
  render() {
    return (
      <div className='full-item' id='booking'>
         <div className='divInfo'>{/*фон}*/}
            <div className='icoBack' onClick={() => this.props.onShowAbout()}><IoIosArrowBack /></div>
            <h3>{this.props.about.title}</h3>
            <br/>
            <p>{this.props.about.text_1}</p>
            <img className='balanceOK' src={this.props.PHPserver + "img/about/" + this.props.about.img_1} />
            <br/>
            <p>{this.props.about.text_2}</p>
            <br/>
            <img className='balanceOK' src={this.props.PHPserver + "img/about/" + this.props.about.img_2} />
            <br/>
            <p>{this.props.about.text_3}</p>
            <br/>
            <img className='balanceOK' src={this.props.PHPserver + "img/about/" + this.props.about.img_3} />
            <br/>
            <p>{this.props.about.text_4}</p>
            <br/>
            <p>{this.props.about.text_5}</p>
            <br/>
            {YouTube(this.props.about.youtube)} {/*запускаем наш плеер ютюб*/}
            <div className='icoDone' onClick={() => this.props.onShowAbout()}><MdDone /></div>
            {/*<img src={"./img/" + this.props.item.img} onClick={() => this.props.onShowInfo()} />*/}
         </div>
         
      </div>
    )
  }
}

export default ShowBooking