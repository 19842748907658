import React from "react"
import Header from "./components/Header"//   ./ - это точка вхождение на сайт т.е. index.js 
import Footer from "./components/Footer"
import Items from "./components/Items"
import Categories from "./components/Categories"
import ShowFullItem from "./components/ShowFullItem"
import ShowBooking from "./components/ShowBooking"
import ShowInfo from "./components/ShowInfo"
import About from "./components/About"
import Contacts from "./components/Contacts"
import Registration from "./components/Registration"
import Login from "./components/Login"
let inMemoryToken = "0"//JWT


class App extends React.Component {/*Мантра читается так: Создаем класс App который будет все наследовать(extends) */
                                 /*от класса  Component ктоторый находится в React (React.Component)*/
   /*КОНСТРУКТОР для работы с состояниями. Такой себе хаб*/                              
   constructor (props){/*здесь мы принимаем состояния (пробсы) от родительского класса*/
      super(props)/*а здесь мы отправляем состояния (пробсы) в родительский класс*/
      /*this.state - это обект содержащий в себе различные состояния*/
      this.state = {
         //PHPserver: "http://jurnal-auto/server/", //расположение PHP директории нужно для fetch
         PHPserver: "/server/", //расположение PHP директории
         
         userData: {
            //name: 'User-name', 
            //email: 'User@gmail.com',
            name: '', 
            email: '',
            login: ''
         },
         info: "",
         about: [],
         contacts: [],
         loginBusy: '',
         orders:[],//сосотяние-массив товаров в корзине, пустой//
         booking:{},//сосотяние-массив товаров в корзине, пустой//
         itemsFiltr:[], //массив в который будем складывать отфильтрованные из items товары//
         items:[//items это всего одно состояние (переменная, значение) пробса содержащее в себе массив. В пробс (можно насувать множество состояний (переменных)) //
            //{
            //   id: 1,
            //   title: 'Стул удобный',
            //   img: 'chair.jpg',
            //   desc: 'Lorem Ipsum is printing and typesetting industry.',
            //   category: 'chairs',
            //   price: '79.99'
            //},
            //{
            //   id: 2,
            //   title: 'Стол',
            //   img: 'table.jpg',
            //   desc: 'Tables is tables printing and typesetting industry.',
            //   category: 'tables',
            //   price: '179.99'
            //},
            //{
            //   id: 3,
            //   title: 'Диван',
            //   img: 'Sofa.jpg',
            //   desc: 'Sofa Ipsum is printing and typesetting industry.',
            //   category: 'sofas',
            //   price: '189.99'
            //},
            //{
            //   id: 4,
            //   title: 'Лампа',
            //   img: 'Lamp.jpg',
            //   desc: 'Lamp Ipsum is printing and typesetting industry.',
            //   category: 'light',
            //   price: '24.99'
            //},
            //{
            //   id: 5,
            //   title: 'Стул мягкий',
            //   img: 'chair_soft.jpg',
            //   desc: 'Chair_soft is printing and typesetting industry.',
            //   category: 'chair',
            //   price: '34.99'
            //}
         ],
         showFullItem: false,//тумблер:показыват-скрывать окно с полным описанием выбраного товара//
         fullItem:{},//сюда потом будем помещать конкретный товар для окна полного описания товара//
         
         showBooking: false,//тумблер:показыват-скрывать окно с полным описанием выбраного товара//
         fullItemBooking:{},//сюда потом будем помещать конкретный товар для окна полного описания товара//
         
         showRegistration: false,
         showLogin: false,

         showAbout: false,//тумблер:показыват-скрывать окно с полным описанием выбраного товара//
         showContacts: false,//тумблер:показыват-скрывать окно с полным описанием выбраного товара//


         categories:[]

         
      }
      
      /*Регистрируем наши функции:*/
      this.StartValidate = this.StartValidate.bind(this)
      this.StateUpdate = this.StateUpdate.bind(this)
      this.onShowAbout = this.onShowAbout.bind(this)
      this.onShowContacts = this.onShowContacts.bind(this)
      this.addToOrder = this.addToOrder.bind(this)
      this.deleteOrder = this.deleteOrder.bind(this)
      this.chooseCategory = this.chooseCategory.bind(this)
      this.onShowItem = this.onShowItem.bind(this)
      this.onPay = this.onPay.bind(this)
      this.pay_step_1 = this.pay_step_1.bind(this)
      this.getCategorySQL = this.getCategorySQL.bind(this)
      this.getGoods = this.getGoods.bind(this)
      this.JWTGet = this.JWTGet.bind(this)
      this.countGoodsOrder = this.countGoodsOrder.bind(this)
      this.onShowBooking = this.onShowBooking.bind(this)
      this.sendBooking = this.sendBooking.bind(this)
      this.onShowInfo = this.onShowInfo.bind(this)
      this.onShowRegistration = this.onShowRegistration.bind(this)
      this.sendRegistration = this.sendRegistration.bind(this)
      this.loginBusyFunc = this.loginBusyFunc.bind(this)
      this.onShowLogin = this.onShowLogin.bind(this)
      this.sendLogin = this.sendLogin.bind(this)
      this.encodeJWT = this.encodeJWT.bind(this)
      this.ExitLogin = this.ExitLogin.bind(this)

   }/*закрываем объект конструктор*/

   render(){/*Класс App отправляет свой  return в метод render(). А render выводит результаты (return) на экран*/
      return (
         <div className="wrapper">
               <div className="user">
                  <h3>{this.state.userData.name} {this.state.userData.login}</h3>
               </div>
            <br/>
            <br/>
            <br/>

            <Header StateUpdate={this.StateUpdate}  orders={this.state.orders} onShowAbout={this.onShowAbout} onShowContacts={this.onShowContacts} onDelete={this.deleteOrder} onPay={this.onPay} countGoodsOrder={this.countGoodsOrder} onShowRegistration={this.onShowRegistration} onShowLogin={this.onShowLogin} userData={this.state.userData} />{/*1)orders: передаем в Header, а потом и в корзину состояние orders с массивом товаров находящихся в корзине т.е. состояние orders со вскм тем что оно содержит */}
                                                                              {/*2)onDelete: передаем в Header, а потом и в корзину переменную onDelete в которую мы поместили нашу функцию deleteOrder*/}
            {this.state.showRegistration && <Registration userData={this.state.userData} onShowRegistration={this.onShowRegistration} sendRegistration={this.sendRegistration} loginBusyFunc={this.loginBusyFunc} loginBusy={this.state.loginBusy}/> }
            {this.state.showLogin && <Login userData={this.state.userData} onShowLogin={this.onShowLogin} sendLogin={this.sendLogin} onShowRegistration={this.onShowRegistration} /> }

            <Categories categories={this.state.categories} chooseCategory={this.chooseCategory}/> {/*1) подключаем сюда компонент Categories*/} 
                                             {/*2)Пробрасываем в Categories нашу функцию chooseCategory по фильтрации товаров в переменной chooseCategory для получения сюда key (id) нажатого там фильтра*/}                                                               
            <Items orders={this.state.orders} onShowItem={this.onShowItem} onShowBooking={this.onShowBooking} items={this.state.itemsFiltr} onAdd={this.addToOrder} />{/*1) подключаем сюда компонент Items,*/}
                                             {/*при этом передаем в него состояние itemsFiltr()т.е. весь массив с товарами отфильтрованный по категории*/}
                                             {/*2)Пробрасываем в Items, а там и в Item нашу функцию по добавлению товаров в корзину в переменной onAdd*/}
                                             {/*3)Пробрасываем в Items, а там и в Item нашу функцию onShowItem по открытию окна с полным описанием товара*/}

            {this.state.showFullItem && <ShowFullItem item={this.state.fullItem} orders={this.state.orders} onShowItem={this.onShowItem} onAdd={this.addToOrder} onShowBooking={this.onShowBooking} />}
                                             {/*Через условие IF: если тумблер showFullItem: true - то выводим блок с полным описанием товара:*/}
                                             {/*Подключаем блок ShowFullItem (полное описание товара)*/}
                                             {/*И передаем в блок ShowFullItem сам товар полученный из item и записанный в fullItem: item={this.state.fullItem} */}
                                             {/*пробрасываем тумблер onShowItem чтобы по нажатию на изображение окна оно закрывалось*/}
                                             {/*пробрасываем функцию onAdd чтобы и из ShowFullItem можно было добавлять навые товары*/}
            {this.state.showBooking && <ShowBooking item={this.state.fullItemBooking} onShowBooking={this.onShowBooking} sendBooking={this.sendBooking} onAdd={this.addToOrder} info={this.state.info} userData={this.state.userData}/> }
            {this.state.info && <ShowInfo item={this.state.fullItemBooking} onShowInfo={this.onShowInfo} info={this.state.info} />}{/*окно информация появится если в состоянии info появится какойнибудь текст*/}
            {this.state.showAbout && <About onShowAbout={this.onShowAbout} about={this.state.about} PHPserver={this.state.PHPserver}/>}{/*окно информация появится если в состоянии info появится какойнибудь текст*/}
            {this.state.showContacts && <Contacts onShowContacts={this.onShowContacts} contacts={this.state.contacts} PHPserver={this.state.PHPserver}/>}{/*окно информация появится если в состоянии info появится какойнибудь текст*/}

            <Footer />
         </div>
      )
   }

   
   //Автозапуск
   componentDidMount() {//componentDidMount штатная функция для изменения состояний в копонентах-классах аналог useEffect в комонентах функциях. Не требуется регистрация в Конструкторе//
		this.StartValidate()
      this.getGoods()
      this.getCategorySQL()
   }
   
   //Функция валидации при перезагрузке страницы
   StartValidate() {
         //let user = this.state.userData//имя юзера//
         fetch(this.state.PHPserver + "login/start_validate_router.php", {
            //ОТПРАВЛЯЕМ//
            method : 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body : JSON.stringify({//это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
               //data_user : user
            })
         })         
            //ПОЛУЧАЕМ//
         //.then(response => response.text())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
         .then(response => response.json())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
         //Проверка успешности запроса://{ info: 'ОЙ! что-то не сработало, попробуйте чуть позже )' }
         .catch((e)=>{Error = 'Error'})//блок catch() обрабатывает ошибки в случае сбоя запроса//
         //.catch(error => error && this.setState({ info: 'ОЙ! что-то не сработало, попробуйте чуть позже )' }))//блок catch() обрабатывает ошибки в случае сбоя запроса//
         
         .then (response =>{ 
            if (response.info === "OK"){//если JWT токен выдан//
            //if (8==8){//если JWT токен выдан//

               inMemoryToken = response.JWT//заносим JWT токин в память (в переменную)
               let encodeJWT = this.encodeJWT(response.JWT)//передаем в нашу функцию декодирования полученный JWT
               this.setState(prevState=>({userData: {...prevState.userData, login: encodeJWT.userLogin, name: encodeJWT.userName }}))//обновляем в сосояние userData//
               {console.log(response)}
               
               //{console.log(inMemoryToken)}//данные из токина JWT
               //{console.log(response.info)}//данные из info
               //{console.log(encodeJWT.user_id)}//данные из токина JWT
               //{console.log(encodeJWT.userName)}//данные из токина JWT
               //{console.log(encodeJWT.userLogin)}//данные из токина JWT
            }      
         });
   }


   //Функция обновления всех состояний по клику
   StateUpdate() {
      //this.setState({})//обновить все состояния
      window.location.reload()//перезагрузить страницу  
   }

   //Функция: получаем массив с товарами из БД
   getGoods() {
      fetch(this.state.PHPserver + "getGoods_function.php", {
         //ОТПРАВЛЯЕМ//
         method : 'POST',
         header : {
            'Content-Type': 'application/x-www-form-urlencoded',
         },
         body : JSON.stringify({//Здесь не требуется, но и не мешает. Приведено для примера. 001, 002 -это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
            data_1 : '001',
            data_2 : '002',
            data_3 : '003'
         })
      })         
      //ПОЛУЧАЕМ//
      .then(response => response.json())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      .then(response => this.setState({ items:response, itemsFiltr:response }))//записываем полученные массивы в items и в itemsFiltr//
      //.then (response => {console.log(response)})
      .catch(error => console.error('Error:', error));//блок catch() обрабатывает ошибки в случае сбоя запроса//
      //console.log(this.state.items)
   }

   //Отслеживаем жмянание по ABOUT//
   onShowAbout() {
      //this.setState({ about: "ABOUT" })
      this.setState({ showAbout: !this.state.showAbout })//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//
   
      fetch(this.state.PHPserver + "about_function.php", {
         //ОТПРАВЛЯЕМ//
         method : 'POST',
         header : {
            'Content-Type': 'application/json',
         },
         body : JSON.stringify({//Здесь не требуется, но и не мешает. Приведено для примера. 001, 002 -это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
            //data_1 : '001',
            //data_2 : '002',
            //data_3 : '003'
         })
      })         
      //ПОЛУЧАЕМ//
      .then(response => response.json())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      .then (response =>{ 
         console.log(response)
         console.log(response)
         this.setState({ about: response })
      });

   }

      //Отслеживаем жмянание по Contacts//
      onShowContacts() {
         this.setState({ showContacts: !this.state.showContacts })//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//
      
         fetch(this.state.PHPserver + "contacts_function.php", {
            //ОТПРАВЛЯЕМ//
            method : 'POST',
            header : {
               'Content-Type': 'application/json',
            },
            body : JSON.stringify({//Здесь не требуется, но и не мешает. Приведено для примера. 001, 002 -это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
               //data_1 : '001',
               //data_2 : '002',
               //data_3 : '003'
            })
         })         
         //ПОЛУЧАЕМ//
         .then(response => response.json())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
         .then (response =>{ 
            console.log(response)
            console.log(response)
            this.setState({ contacts: response })
         });
   
      }
   

 
   //Оплата//
   onPay() {//item - это товар переданный в эту функцию из блоки Item при жмякание по картинке товара//
      if (this.state.userData.login!=='') {//проверяем залогинился ли пользователь
      //if (8==8) {
         console.log('login-ok')      
         let ordersPay = this.state.orders
         let user = this.state.userData//имя юзера//
         //console.log(ordersPay)
         fetch(this.state.PHPserver + "ordersPay_function.php", {
            //ОТПРАВЛЯЕМ//
            //mode: 'no-cors',
            method : 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${inMemoryToken}`
            },

            body : JSON.stringify({//это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
               data_ordersPay : ordersPay,
               data_user : user
            })
         })         
         
         //ПОЛУЧАЕМ//
         //.then(response => response.text())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
         .then(response => response.json())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
         .catch((e)=>{Error = 'Error'})//блок catch() обрабатывает ошибки в случае сбоя запроса//
         //.catch(error => console.error('Error:', error));//блок catch() обрабатывает ошибки в случае сбоя запроса//

         .then (response =>{ 
            if (response.info === "OK"){//если JWT токен выдан//
               inMemoryToken = response.JWT//заносим JWT токин в память (в переменную)
               let encodeJWT = this.encodeJWT(response.JWT)//передаем в нашу функцию декодирования полученный JWT
               this.setState(prevState=>({userData: {...prevState.userData, login: encodeJWT.userLogin, name: encodeJWT.userName }}))//обновляем в сосояние userData//
               {console.log(response)}

               //Проверка успешности записи в БД
               if (response.info_DB==="OK") {//проверка: в БН все товары из корзины записаны успешно
                  this.pay_step_1()//тогда можно очистить корзину                  
               }else{
                  this.setState({ info: "UPS! something didn't work, try again later)" })
               }
                  
               
               //{console.log(inMemoryToken)}//данные из токина JWT
               //{console.log(response.info)}//данные из info
               //{console.log(response.info_2)}//данные из info
               //{console.log(encodeJWT.user_id)}//данные из токина JWT
               //{console.log(encodeJWT.userName)}//данные из токина JWT
               //{console.log(encodeJWT.userLogin)}//данные из токина JWT
               
            }else{
               this.onShowLogin()//если от валидатора пришол ответ не-ОК, пусть юзер логинется
            }     
         });    
         
      }else{
         this.onShowLogin()//если юзер не залогинился, пусть логинется 
      }
   }

   pay_step_1(){
      //alert ('Перейти к оплатте?')
      this.setState({ orders:[] })//очистить корзину от товаров//
      this.setState({ info:'Application accepted!\nYour order is already being prepared for shipping.\nThank you for your trust!\n\n! We remind you that the site works in demo mode, the order has not been placed!'})
      this.getGoods()//обновляем базу товаров
      //console.log(document.cookie);
   }



   //Категории//
   getCategorySQL() {//item - это товар переданный в эту функцию из блоки Item при жмякание по картинке товара//
      //let ordersPay = this.state.orders
      //console.log(ordersPay)
      fetch(this.state.PHPserver + "category_function.php", {
         //ОТПРАВЛЯЕМ//
         method : 'POST',
         header : {
            'Content-Type': 'application/x-www-form-urlencoded',
         },
         body : JSON.stringify({//это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
            //data_1 : ordersPay
         })
      })         
      //ПОЛУЧАЕМ//
      .then(response => response.json())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      .then(response => this.setState({ categories:response }))//записываем полученные массивы в items и в itemsFiltr//
      //.then (response => {console.log(response)})//чтобы лог ссработал нужно переключить response на .text() и закоментировать строку .then(response => this.setState({ categories:response }))

   }

   //Фильтр товаров (наша функция)//
   chooseCategory(category){
      if(category === 'all') {//если выбрано: Все товары//
         this.setState({itemsFiltr: this.state.items})
         return//выходим из функции//
      }
      this.setState({ 
         itemsFiltr: this.state.items.filter(el => el.category === category) 
      })
   }



   //Отслеживаем жмянание по картинке товара//
   onShowItem(item) {//item - это товар переданный в эту функцию из блоки Item при жмякание по картинке товара//
      this.setState({ fullItem: item })//передаем в состаяние FullItem, товар полученный в эту функцию//
      this.setState({ showFullItem: !this.state.showFullItem })//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//
      //console.log(item)
   }

   //Отслеживаем жмянание по кнопке оставить заявку//
   onShowBooking(item) {//item - это товар переданный в эту функцию из блоки Item при жмякание по картинке товара//
      this.setState({ fullItemBooking: item })//передаем в состаяние FullItem, товар полученный в эту функцию//
      this.setState({ showBooking: !this.state.showBooking })//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//
      this.setState({ showFullItem: false})//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//
      //console.log(item)
   }

   //Отслеживаем жмянание по кнопке отправить заявку//
   sendBooking(item) {//item - это товар переданный в эту функцию из блоки Item при жмякание по картинке товара//
      this.setState({ user: this.state.userData})//обновляем сосояние userData//
      this.setState({ booking: item })//передаем в состаяние booking, товар полученный в эту функцию//
      //this.setState({ showBooking: !this.state.showBooking })//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//

      let user = this.state.userData//имя юзера//


      fetch(this.state.PHPserver + "booking_function.php", {
         //ОТПРАВЛЯЕМ//
         method : 'POST',
         header : {
            'Content-Type': 'application/x-www-form-urlencoded',
         },
         body : JSON.stringify({//это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
            data_itemBooking: item,//так можно передать весь массив item целиком//
            data_user : user,//или можно передавать уже готовае элементы массива по отдельности//
            //data_id : item.id,
            //data_title : item.title
          
         })
      })         
      //ПОЛУЧАЕМ//
      .then(response => response.text())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      //.then (response => {console.log(response)})
      //.catch(error => console.error('Error:', error))//блок catch() обрабатывает ошибки в случае сбоя запроса//
      //Проверка успешности запроса://
      .then (response =>{if (response==='SAVE-OK'){//если SQL запрс прошел успешно//
                           {console.log(response)}
                           this.setState({ showBooking: !this.state.showBooking })//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//
                           this.setState({ info: 'Your application has been sent' })//чистим сосояние info//
                        }else{//если SQL не прошел//
                           {console.log('Error SQL:')}
                           {console.log(response)}
                           this.setState({ info: "UPS! something didn't work, try again later)" })
                        }
                     });
                     
   
   }

   //Отслеживаем жмянание по кнопке оставить заявку//
   onShowInfo() {//item - это товар переданный в эту функцию из блоки Item при жмякание по картинке товара//
      this.setState({ info: '' }) //чистим инфо
      this.setState({ fullItemBooking: '' })   //чистим заявку
   }


   

   //Удаление товаров из корзины (наша функция)//
   deleteOrder(id){
      //Эта функция меняет состояние orders т.е. массив товаров в корзине. Следующим образом:/
      //прогоняет весь массив orders через цикл filter и оставляет в массиве только те товары id которых не равны (!== id) id, переданному в эту функцию из кнопки (иконки) "удалить товар из корзины" (т.е.из Ordes)//
      //*а затем полученный отфильтрованый массив (т.е. без удаляемого товара) переписывает заново в массив-состояние  orders:*/
      this.setState({ orders: this.state.orders.filter(el => el.id !== id) })
   }

  //Функция (наш метод) добавления товаров в корзину//
   addToOrder(item) {//функция получает item т.е. конкретный товар от нажатой кнопки "добавить товар в корзину" //
      this.getGoods()//обновляем базу товаров
      //запрет на дублирование товара в корзине://
      let isInArray = false//переменная уже есть ли в массиве такой товар, по умолчанию false - нет такого товара//
      this.state.orders.forEach(el => {/*перебираем массив циклом forEach*/
         if (el.id === item.id)/*если id нашего нового товара (item.id) уже имеется в перебираемом массиве(el.id)*/
            isInArray = true/*установим переменной isInArray значение true*/
      })
      if (!isInArray){/* у булеевой переменной isInArray значение false (!), то добавляем в козину новый товар*/    
         item.countGoods = 1 //добавляем в массав свойство: количество//
         this.setState({ orders: [...this.state.orders,item]})
      }
      //alert (this.state.orders.price)
       //console.log(item)
   }//записываем в массив товаров в корзине все товары (...this.state.orders) которые и так были в ней плюс один товар (item) переданный в функцию из кнопки "добавить товар" () //
   
   //КОЛИЧЕСТВО ТОВАРА в корзине изменить//
    countGoodsOrder(item, count) {//функция получает item т.е. конкретный товар и -1 или +1 //
      //Эта функция меняет состояние orders т.е. массив товаров в корзине. Следующим образом:/
      if (count===1||count===-1){//если меняем количестко кнопками +-//
         item.countGoods = +item.countGoods + count //Плюсик (+item) нужен для преобразования текста в число. Добавляем в массав свойство: количество//
         //alert (item.countGoods)   
      }//обновляем сосояние всей корзины//
      else
         item.countGoods = count//значит меняем количестко полем импут//

      if (item.countGoods<1) item.countGoods = 1
      if (item.countGoods>item.balance) item.countGoods = +item.balance 
      this.setState({ orders: [...this.state.orders]})//обновляем сосояние всей корзины//
      //item.countGoods <1 && this.setState({ orders: this.state.orders.filter(el => el.id !== id), item })
      //:Если количество конкретного товара в корзине меньше одного - удаляем этот товар из корзины://
      //    -прогоняет весь массив orders через цикл filter и оставляет в массиве только те товары id которых не равны (!== id) id, переданному в эту функцию из кнопки (иконки) "удалить товар из корзины" (т.е.из Ordes)//
      //*   -а затем полученный отфильтрованый массив (т.е. без удаляемого товара) переписывает заново в массив-состояние  orders:*/

   }

   //Отслеживаем жмянание по кнопке Зарегистрироваться//
   onShowRegistration() {
      //this.setState({ fullItemBooking: item })//передаем в состаяние FullItem, товар полученный в эту функцию//
      this.setState({ showRegistration: !this.state.showRegistration })//showRegistration: записываем в булеевое состояние его противоположное значение true-false-true//
      //this.setState({ showFullItem: false})//showFullItem: записываем в булеевое состояние его противоположное значение true-false-true//
   }

   //Отслеживаем жмянание по кнопке отправить Зарегистрироваться//
   sendRegistration(userReg) {//item - это товар переданный в эту функцию из блоки Item при жмякание по картинке товара//
      let user = userReg
      console.log(user)

      fetch(this.state.PHPserver + "login/registration_function.php", {
         //ОТПРАВЛЯЕМ//
         method : 'POST',
         header : {
            'Content-Type': 'application/x-www-form-urlencoded',
         },
         body : JSON.stringify({//это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
            //data_title : item.title,
            data_user : user
         })
      })         
      //ПОЛУЧАЕМ//
      .then(response => response.text())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      //Проверка успешности запроса://
      .then (response =>{if (response==='login-busy') {//сначала проверим свободен ли логин
                           //this.setState({ info: "Этот логин уже занят, попробуйте другой" })
                           this.setState({ loginBusy: "This login is already taken, try another one. " })

                        }else{
                           if (response==='SAVE-OK'){//если SQL запрс прошел успешно//
                              {console.log(response)}
                              this.setState({ showRegistration: !this.state.showRegistration })//showRegistration: записываем в булеевое состояние его противоположное значение true-false-true т.е. скрываем блок Registration//
                              this.setState({ info: 'Are you registered' })//чистим сосояние info//
                              this.setState({ showLogin: !this.state.showLogin })//showRegistration: записываем в булеевое состояние его противоположное значение true-false-true//
                           }else{//если SQL не прошел//
                              //{console.log('Error SQL:')}
                              {console.log(response)}
                              this.setState({ info: "UPS! something didn't work, try again later)" })
                           }
                        }
                     });
                     
   }

   //функция для обнуления ошибки, что логин уже занят, к ней одращается из блока Registration
   loginBusyFunc(){
      this.setState({ loginBusy: '' })
   }

   //Отслеживаем жмянание по кнопке Войти-выйти//
   onShowLogin(){
      if (this.state.userData.login==='') {//проверяем, если пользователь УЖЕ залогинился, тогда значит он хочет разлогиниться
         this.setState({ showLogin: !this.state.showLogin })//showRegistration: записываем в булеевое состояние его противоположное значение true-false-true//
      }else{
         this.ExitLogin()
      }
   }

   sendLogin(userAuthorization){
      //this.setState(prevState=>({userData: {...prevState.userData, login: userAuthorization.login}}))//обновляем в сосояние userData только два элемента: name и email//
      //this.setState({ showLogin: !this.state.showLogin })//showRegistration: записываем в булеевое состояние его противоположное значение true-false-true//
      //console.log(userAuthorization)

      let user = userAuthorization
      let Error =''
      let encodeJWT
      //console.log(user)

      fetch(this.state.PHPserver + "login/authorization_function.php", {
         //ОТПРАВЛЯЕМ//
         method : 'POST',
         headers : {
            'Content-Type': 'application/x-www-form-urlencoded',
         },
         body : JSON.stringify({//это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
            //data_title : item.title,
            data_user : user
         })
      })         
      //ПОЛУЧАЕМ//
      //.then(response => response.text())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      .then(response => response.json())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      //.then(response => response.text())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      //Проверка успешности запроса://{ info: 'ОЙ! что-то не сработало, попробуйте чуть позже )' }
      .catch((e)=>{Error = 'Error'})//блок catch() обрабатывает ошибки в случае сбоя запроса//
      //.catch(error => error && this.setState({ info: 'ОЙ! что-то не сработало, попробуйте чуть позже )' }))//блок catch() обрабатывает ошибки в случае сбоя запроса//

      .then (response =>{ if (Error === 'Error') {
                           this.setState({ info:"UPS! something didn't work, try again later)" })
                        }else{
                           if (response.info === "OK"){//если JWT токен выдан//
                              inMemoryToken = response.JWT//заносим токин в память
                              encodeJWT = this.encodeJWT(response.JWT)//передаем в нашу функцию декодирования полученный JWT
                              {console.log(inMemoryToken)}//данные из токина JWT
                              {console.log(response.info)}//данные из токина JWT
                              {console.log(encodeJWT.user_id)}//данные из токина JWT
                              {console.log(encodeJWT.userName)}//данные из токина JWT
                              {console.log(encodeJWT.userLogin)}//данные из токина JWT
                              this.setState(prevState=>({userData: {...prevState.userData, login: encodeJWT.userLogin, name: encodeJWT.userName }}))//обновляем в сосояние userData только два элемента: name и email//
                              this.setState({ showRegistration: !this.state.showLogin })//showRegistration: записываем в булеевое состояние его противоположное значение true-false-true т.е. скрываем блок Registration//
                              this.setState({ showLogin: !this.state.showLogin })//showRegistration: записываем в булеевое состояние его противоположное значение true-false-true//
                              this.setState({ info: encodeJWT.userName + ', welcome!' })//чистим сосояние info//
                              //this.JWTGet(response)
                           }else{//если SQL не прошел//
                              {console.log(response)}
                              this.setState({ info: 'Wrong login or password' })
                           }
                         }
                       });
   }

   


   //Функция: получаем КУКИ
   JWTGet(user_response) {
      let encodeJWT
      let user = { // объект
         name: user_response.name,  // под ключом "name" хранится значение "John"
         login: user_response.login        // под ключом "age" хранится значение 30
       }

      //let user = this.state.userData
      fetch(this.state.PHPserver + "login/JWT.php", {
         //ОТПРАВЛЯЕМ//
         method : 'POST',
         header : {
            'Content-Type': 'application/x-www-form-urlencoded',
         },
         body : JSON.stringify({//Здесь не требуется, но и не мешает. Приведено для примера. 001, 002 -это то что мы передам на сервер PHP, это может быть любая информация, как передача в функцию//
            //data_1 : '001',
            //data_2 : '002',
            //data_3 : '003'
            data_user : user
         })
      })         
      //ПОЛУЧАЕМ//
      .then(response => response.text())//метод then(): используется для обработки ответа, преобразуя его в JSON или text//
      .then (response =>{ 
         //console.log(response)
         inMemoryToken = response//заносим токин в память
         encodeJWT = this.encodeJWT(response)//передаем в нашу функцию декодирования полученный JWT
         console.log(encodeJWT)//объект целиком
         console.log(encodeJWT.userLogin)//или элементы объекта
         console.log(encodeJWT.userName)//или элементы объекта
         console.log(inMemoryToken)//сам токен         
      });
   }

   

   //Декодирование JWT
   encodeJWT(JWT) {
      //console.log(JWT)
      let base64Url = JWT.split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      let encodeJWT = JSON.parse(jsonPayload)
      return encodeJWT
   }

   //Функция для разлогинивания: Exit
   ExitLogin(){
      //console.log('ExitLogin - TADAM!') 
      inMemoryToken ='0'//чистим токен JWT
      this.setState(prevState=>({userData: {...prevState.userData, login: '', name: '' }}))//чистим в сосояние userData только два элемента: name и email//
   }

   



}

export default App;


