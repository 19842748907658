import React, { Component } from 'react'
import { MdDone } from "react-icons/md"

export class ShowBooking extends Component {
  render() {
    return (
      <div className='full-item' id='booking'>
         <div className='divInfo'>{/*фон}*/}
            <h3>{this.props.info}</h3>
            <div className='icoDone' onClick={() => this.props.onShowInfo()}><MdDone /></div>
            {/*<img src={"./img/" + this.props.item.img} onClick={() => this.props.onShowInfo()} />*/}
         </div>
         
      </div>
    )
  }
}

export default ShowBooking